import { useCallback } from 'react'
import { useToast } from '@/molecules/Toast'
import { useSafeTrack } from '@/utils/analytics'
import {
  KnownReservationErrors,
  TheatricalErrorContext,
  trackKnownReservationError,
} from '@/views/TicketCheckoutViews/utils/errorTrackingUtils'

interface HandleKnownErrorProps {
  errors: KnownReservationErrors
  context: TheatricalErrorContext
  reservationId?: string | undefined
}

const useHandleKnownError = () => {
  const track = useSafeTrack()
  const { showToast } = useToast()

  const handleKnownError = useCallback(
    ({ errors, context, reservationId }: HandleKnownErrorProps) => {
      if (errors && errors?.length > 0) {
        trackKnownReservationError({ context, errors, track, reservationId: reservationId ?? 'UNKNOWN' })
        showToast(errors?.[0]?.description ?? '')
      }
    },
    [showToast, track],
  )

  return { handleKnownError }
}

export default useHandleKnownError
