import React, { InputHTMLAttributes, useRef } from 'react'
import classNames from 'classnames'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { IconColor } from '@/atoms/utils'
import { ReactFCC } from '@/types/react'

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  checkedClassName?: string
  uncheckedClassName?: string
  labelClassName?: string
  wrapperClassName?: string
  checkboxSize?: number
  color?: IconColor
}

export const CheckBox: ReactFCC<CheckBoxProps> = ({
  checked,
  children,
  color,
  onChange,
  onBlur,
  className,
  checkedClassName,
  uncheckedClassName,
  labelClassName,
  checkboxSize,
  wrapperClassName,
  id = 'check-box',
  ...props
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null)

  const uncheckedClasses = classNames(
    'shrink-0',
    'focus-visible:ring-transparent',
    'focus-visible:ring-0',
    'focus-visible:ring-offset-0',
    'focus-visible:ring-offset-transparent',
    'border-2',
    'rounded-lg',
    'border-gray-400',
    'w-6',
    'min-w-6',
    'h-6',
    'min-h-6',
    'md:w-8',
    'md:h-8',
    uncheckedClassName,
  )

  const checkedClasses = classNames(
    'shrink-0',
    'focus-visible:ring-transparent',
    'focus-visible:ring-0',
    'focus-visible:ring-offset-0',
    'focus-visible:ring-offset-transparent',
    'bg-oxide',
    'rounded-lg',
    'w-6',
    'min-w-6',
    'h-6',
    'min-h-6',
    'md:w-8',
    'md:h-8',
    checkedClassName,
  )

  const handleCheckBoxClick = () => {
    checkboxRef.current?.click()
  }

  return (
    <div className={classNames('flex items-center justify-items-center', wrapperClassName)}>
      {checked ? (
        <div onClick={handleCheckBoxClick} className={classNames(checkedClasses)}>
          <CheckIcon color={color} className="block md:hidden" size={checkboxSize ?? 24} />
          <CheckIcon color={color} className="hidden md:block" size={checkboxSize ?? 32} />
        </div>
      ) : (
        <div onClick={handleCheckBoxClick} className={classNames(uncheckedClasses)} />
      )}
      <label
        htmlFor={id}
        className={labelClassName ? labelClassName : 'ml-2 flex cursor-pointer items-center py-4 text-xs'}
      >
        {children}
      </label>
      <input
        id={id}
        ref={checkboxRef}
        checked={checked}
        data-testid={children}
        type="checkbox"
        className={classNames('hidden', className)}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
    </div>
  )
}
